<template>
  <div class="path-map">
    <l-map @ready="mapReady" class="l-map" ref="leafmap" >
      <l-tile-layer :url="url" :attribution="attribution"></l-tile-layer>
    </l-map>

    <div class="outside-nav">
      <flickity ref="flickity" class="f-main" :options="flickityOptions">
        <div class="f-cell" v-for="(m, mIndex) in allMilestones" :key="mIndex">
          <button class="nav-btn" @click="flickitySelect(mIndex)">{{m.name}}</button>
        </div>
      </flickity>
    </div>

  </div>
</template>

<script>
import Flickity from 'vue-flickity';
import L from 'leaflet';
import { LMap, LTileLayer } from 'vue2-leaflet';
import 'leaflet/dist/leaflet.css';

export default {
  name: 'PathMap',
  props: {
    path: {
      type: Array,
      required: false,
      default() {
        return [];
      },
    },
    milestones: {
      type: Array,
      required: false,
      default() {
        return [];
      },
    },
    debug: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      initDraw: false,
      pathReady: false,
      mapApiReady: false,

      parentReady: false,

      startIcon: null,
      endIcon: null,
      milestoneIcon: null,

      allPath: [],
      // routeLine: null,

      allMilestones: [],
      milestoneMarkers: [],
      selectingMilestoneSerial: -1,

      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      attribution: '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',

      flickity: null,
      flickityOptions: {
        cellAlign: 'center',
        // contain: true,
        pageDots: false,
        prevNextButtons: false,

        // any options from Flickity can be used
      }
    };
  },
  components: {
    Flickity,
    LMap,
    LTileLayer,
  },
  watch: {
    path() {
      console.log('path changed.');
    },
    milestones() {
      console.log('milestone changed.');
    },
    selectingMilestoneSerial(v) {
      for (const m of this.milestoneMarkers) {
        if (m.__tempMilestoneSerial === v) {
          m.openPopup();
          return;
        }
      }
    },
  },
  created() {
    this.reloadPathAndMilestone();
  },
  mounted() {
    this.startIcon = L.icon({
      iconUrl: 'https://maps.google.com/mapfiles/kml/paddle/go.png',
      iconSize: [32, 32],
      iconAnchor: [16, 32],
    });

    this.endIcon = L.icon({
      iconUrl: 'https://maps.google.com/mapfiles/kml/paddle/grn-square.png',
      iconSize: [32, 32],
      iconAnchor: [16, 32],
    });

    this.milestoneIcon = L.icon({
      iconUrl: 'https://maps.google.com/mapfiles/kml/paddle/grn-blank.png',
      // iconUrl: 'http://maps.google.com/mapfiles/kml/paddle/ylw-blank.png',
      // iconUrl: 'https://joyhike.s3.ap-northeast-3.amazonaws.com/location.svg',
      iconSize: [32, 32],
      iconAnchor: [16, 32],
    });

    setTimeout(() => {
      this.parentReady = true;
    }, 0);

    setTimeout(() => {
      // console.log(this.$refs.flickity);
      this.$refs.flickity.on('select', this.flickityOnSelect);
    }, 200);
  },
  computed: {
  },
  methods: {
    reloadMilestone() {
      this.allMilestones.splice(0, this.allMilestones.length);
      this.allMilestones = [...new Set(this.milestones)];
      // this.milestones.map((item) => {
      //   const found = this.allMilestones.filter((m) => {
      //     return m.serial === item.serial;
      //   });
      //   if (found.length === 0) {
      //     this.allMilestones.push(item);
      //   }
      // });
    },
    reloadPath() {
      this.allPath.splice(0, this.allPath.length);
      this.path.forEach(({ lat, lng }) => {
        this.allPath.push({ lat, lng });
      });
      // 需要新創常數儲存時 map > foreach, 使用原本就存於data的數值 foreach > map
      // this.path.map(item => {
      //   this.allPath.push({
      //     lat: item.lat,
      //     lng: item.lng
      //   });
      // });
    },
    reloadPathAndMilestone() {
      this.reloadMilestone();
      this.reloadPath();
      this.pathReady = true;
      this.tryInitDraw();
    },
    tryInitDraw() {
      if (this.pathReady && this.mapApiReady && !this.initDraw) {
        this.initDraw = true;
        this.autoFitMap();
        this.showPathOnMap();
      }
    },
    mapReady() {
      const mapObject = this.$refs.leafmap.mapObject;
      this.mapApiReady = true;
      // const startPos = [this.route.start.lat, this.route.start.lng];
      // const endPos = [this.route.end.lat, this.route.end.lng];
      // this.__reloadPathAndMilestone();
      // this.autoFitMap();
      // this.showPathOnMap();
      // L.marker(startPos, {icon: this.startIcon}).addTo(mapObject);
      // L.marker(endPos, {icon: this.endIcon}).addTo(mapObject);
      mapObject.on('click', this.mapClicked);
      this.tryInitDraw();
    },
    autoFitMap: function(){
      const leafBounds = L.latLngBounds();
      // for(const m of this.allPath){
      //   leafBounds.extend(L.latLng(m.lat, m.lng));
      // }
      this.allPath.forEach(item => {
        leafBounds.extend(L.latLng(item.lat, item.lng));
      });
      // console.log(this.allPath);
      this.$refs.leafmap.mapObject.fitBounds(leafBounds);
    },
    mapClicked(evt) {
      console.log({
        lat: evt.latlng.lat,
        lng: evt.latlng.lng
      });
    },
    showPathOnMap() {
      // if (this.routeLine !== null) {
      //   this.routeLine.remove();
      // }
      // let tempLatlngs = [];
      // for (const p of this.allPath) {
      //   tempLatlngs.push([p.lat, p.lng]);
      // }
      // this.routeLine = L.polyline(tempLatlngs, {color: 'blue'});
      // this.routeLine.addTo(this.$refs.leafmap.mapObject);
      // this.routeLine 僅此用到

      const tempLatlngs = this.allPath.map(item => [item.lat, item.lng]);
      const routeLine = L.polyline(tempLatlngs, { color: 'blue' });
      routeLine.addTo(this.$refs.leafmap.mapObject);

      // if (this.milestoneMarkers.length > 0) {
      //   this.milestoneMarkers.map(item => {
      //     item.remove();
      //   });
      //   this.milestoneMarkers.splice(0, this.milestoneMarkers.length);
      // }
      this.milestoneMarkers.splice(0, this.milestoneMarkers.length);

      // for (const m of this.allMilestones) {
      //   const tempMarker = L.marker([m.lat, m.lng], {
      //     icon: this.milestoneIcon,
      //     draggable: false,
      //     title: m.name,
      //   });
      //   tempMarker.addTo(this.$refs.leafmap.mapObject).bindPopup(m.name);
      //   // tempMarker.on('click', item => {
      //   //   console.log(item);
      //   // });
      //   tempMarker.__tempMilestoneSerial = m.serial;
      //   this.milestoneMarkers.push(tempMarker);
      // }
      this.milestoneMarkers = this.allMilestones.map(item => {
        const tempMarker = L.marker([item.lat, item.lng], {
          icon: this.milestoneIcon,
          draggable: false,
          title: item.name,
        });
        tempMarker.addTo(this.$refs.leafmap.mapObject).bindPopup(item.name);
        tempMarker.__tempMilestoneSerial = item.serial;
        return tempMarker;
      });
    },
    flickitySelect(index) {
      this.$refs.flickity.select(index);
      console.log(this.$refs.flickity.selectedIndex())
    },
    flickityOnSelect(index) {
      this.selectingMilestoneSerial = this.milestones[index].serial;
    },
    mapSizeChanged() {
      this.$refs.leafmap.mapObject.invalidateSize();
    },
  },
}
</script>

<style lang="scss" scoped>
  @import "src/assets/scss/basic";
  .path-map {
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 0;
  }

  .l-map {
    width: 100%;
    height: 100%;
  }

  .m-list {
    margin-left: -10px;
    margin-bottom: -10px;
    padding: 15px 15px 24px;
    background-color: #fff6;
  }

  .f-main {

  }

  .f-cell {
    padding: 5px;
  }

  .outside-nav {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 15px 15px 24px;
    background-color: #fff6;
    z-index: 400;
  }

  .nav-btn {
    display: inline-block;
    font-weight: 400;
    color: #212529;
    text-align: center;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    font-size: 1rem;
    line-height: 1.5;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    font-size: .875rem;
    border-radius: 1.25rem;
    padding: 0.5rem 1.25rem;
    background-color: #eee;
    color: #333;
    border: none;
  }

  .nav-btn:hover {

  }

  .nav-btn:active {

  }

  .nav-btn:focus {

  }

  .is-selected .nav-btn {
    background-color: #666;
    color: #fff;
  }
</style>
